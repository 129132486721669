import classNames from 'classnames';
import { Placement } from 'popper.js';
import React, { Component, HTMLProps } from 'react';
import Autosuggest, { RenderSuggestionsContainerParams } from 'react-autosuggest';
import { Manager, Popper, PopperChildrenProps, Reference } from 'react-popper';
import { connect } from 'react-redux';
import TagsInput, { RenderInputProps, RenderTagProps } from 'react-tagsinput';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { KeywordsThunks } from '../../../api/recruitments/keywords/keywords.thunk';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import styles from './Keywords.module.scss';

const ON_CHANGE_REQUEST_DELAY = 300;

type KeywordsProps = Omit<HTMLProps<HTMLInputElement>, 'value'> & {
    value: string[];
    name: string;
    newSelectionPrefix?: string;
    foundKeywords: string[];
    placement?: Placement;
    fetchKeywords: (startingWith: string, page?: number, pageSize?: number) => Promise<void>;
    setFieldValue: (field: string, value: string[]) => void;
};

class Keywords extends Component<KeywordsProps> {
    private requestTask?: number;
    private updatePopperPlacement?: () => void;

    componentDidMount() {
        this.props.fetchKeywords('');
    }

    render() {
        const inputProps = {
            placeholder: this.props.placeholder
        };

        return (
            <Manager>
                <TagsInput
                    className={classNames(styles.container, 'form-control py-0')}
                    value={this.props.value}
                    onChange={this.onChange}
                    inputProps={inputProps}
                    renderInput={this.renderInput}
                    renderTag={this.renderTag}
                    addOnBlur
                    addOnPaste
                    addKeys={[9, 13, 188]} // tab, enter, ","
                />
            </Manager>
        );
    }

    private renderInput = (props: RenderInputProps) => {
        const onInputChange = (e: React.FormEvent<HTMLInputElement>, params: Autosuggest.ChangeEvent) => {
            const val = e.currentTarget.value;
            this.fetchKeywords(val);

            if (params.method === 'enter') {
                e.preventDefault();
            } else {
                props.onChange(e as any);
            }
        };

        const { addTag, ...inputProps } = props;

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={this.props.foundKeywords}
                shouldRenderSuggestions={() => true}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={{ ...inputProps, onChange: onInputChange }}
                onSuggestionSelected={(e, { suggestion }) => {
                    addTag(suggestion);
                }}
                onSuggestionsClearRequested={() => {}}
                onSuggestionsFetchRequested={() => {}}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                renderInputComponent={this.renderAutosuggestInput}
            />
        );
    };

    private renderAutosuggestInput = (props: any) => {
        return (
            <Reference>
                {({ ref }) => (
                    <div ref={ref}>
                        <input
                            type='text'
                            {...props}
                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                props.onFocus(e);

                                if (this.updatePopperPlacement) {
                                    this.updatePopperPlacement();
                                }
                            }}
                        />
                    </div>
                )}
            </Reference>
        );
    };

    private renderTag = (props: RenderTagProps) => {
        return (
            <div className={styles.tag} key={props.key}>
                <div className={styles.tagText}>{props.getTagDisplayValue(props.tag)}</div>
                <div className={styles.tagRemove} onClick={() => props.onRemove(props.key)}>
                    <i className='fa fa-times' />
                </div>
            </div>
        );
    };

    private renderSuggestionsContainer = (props: RenderSuggestionsContainerParams) => {
        const renderSuggestionsItems = (popperProps: PopperChildrenProps) => {
            if (!this.updatePopperPlacement) {
                this.updatePopperPlacement = popperProps.scheduleUpdate;
            }

            return (
                <div
                    ref={popperProps.ref}
                    data-placement={popperProps.placement}
                    className={classNames(styles.dropdownContainer, popperProps.placement)}>
                    <div {...props.containerProps} className={classNames(props.containerProps.className)}>
                        {props.children}
                    </div>
                </div>
            );
        };

        return <Popper placement={this.props.placement || 'bottom'}>{renderSuggestionsItems}</Popper>;
    };

    private renderSuggestion = (suggestion: string) => {
        return <div className={styles.autocompleteItem}>{suggestion}</div>;
    };

    private onChange = (tags: string[]) => {
        this.props.setFieldValue(this.props.name, tags);
    };

    private fetchKeywords = (inputValue: string) => {
        if (this.requestTask !== undefined) {
            // clear timeout
            window.clearTimeout(this.requestTask);
            this.requestTask = undefined;
        }

        this.requestTask = window.setTimeout(async () => {
            await this.props.fetchKeywords(inputValue);

            if (this.updatePopperPlacement) {
                this.updatePopperPlacement();
            }
        }, ON_CHANGE_REQUEST_DELAY);
    };
}

const mapStateToProps = (state: State) => ({
    foundKeywords: listMapper(state.keywords.list, state.keywords.items).map(keyword => keyword.keyword)
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    fetchKeywords: (startingWith: string, page?: number, pageSize?: number) =>
        dispatch(KeywordsThunks.getKeywords(startingWith, page, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
