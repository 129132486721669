import { Dropdown, DropdownItem } from '@emplo/react-inspinia';
import classNames from 'classnames';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Dictionary } from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { endpoints } from '../../../api/endpoints';
import {
    AddRecruitmentRecruiteeNoteCommand,
    GetRecruiteesInRecruitmentListQueryResult,
    LogRecruitmentRecruiteePhoneCallCommand,
    RECRUITEE_MAX_SCORE,
    ScheduleMeetingCommand,
} from '../../../api/recruitees/recruitees.dto';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import {
    RecruitmentRecruiteeStageListViewDto,
} from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import { TOOLTIP } from '../../../tooltips';
import { date, datetime } from '../../../utils/format';
import RecruiteeAppFormModal from '../../recruitees/common/RecruiteeAppFormModal';
import RecruiteeCvModal from '../../recruitees/common/RecruiteeCvModal';
import RecruiteeGiveScoreModal from '../../recruitees/common/RecruiteeGiveScoreModal';
import RecruiteeReviewsModal from '../../recruitees/common/RecruiteeReviewsModal';
import RecruiteeActionAddNoteModal, {
    FormValues as AddNoteFormValues,
} from '../../recruitees/view/action/RecruiteeActionAddNoteModal';
import RecruiteeActionLogPhoneCallModal, {
    FormValues as LogPhoneCallFormValues,
} from '../../recruitees/view/action/RecruiteeActionLogPhoneCallModal';
import RecruiteeActionMeetingModal, {
    FormValues as ScheduleMeetingFormValues,
} from '../../recruitees/view/action/RecruiteeActionMeetingModal';
import RecruiteeViewScore from '../../recruitees/view/RecruiteeViewScore';
import RecruiteeInRecruitmentStageEditor from './RecruiteeInRecruitmentStageEditor';
import styles from './RecruiteesInRecruitmentList.module.scss';

type OwnProps = GetRecruiteesInRecruitmentListQueryResult & {
    recruitmentId: string;
};

interface OwnState {
    isCvModalOpen: boolean;
    isAppFormModalOpen: boolean;
    isScoreModalOpen: boolean;
    scoreClickedInList: number;
    isReviewsModalOpen: boolean;
    isActionCallModalOpen: boolean;
    isActionAddNoteModalOpen: boolean;
    isActionMeetingModalOpen: boolean;
}

interface StateProps {
    recruitmentStages: Dictionary<RecruitmentRecruiteeStageListViewDto>;
}

interface DispatchProps {
    setScoreForRecruitee: (recruiteeId: string, score: number, comment: string) => Promise<ThunkActionResult>;
    getScoreForRecruiteeInRecruitment: (recruiteeId: string, recruitmentId: string) => Promise<void>;
    logPhoneCall: (command: LogRecruitmentRecruiteePhoneCallCommand) => Promise<ThunkActionResult>;
    addNote: (command: AddRecruitmentRecruiteeNoteCommand) => Promise<ThunkActionResult>;
    scheduleMeeting: (command: ScheduleMeetingCommand) => Promise<ThunkActionResult>;
}

class RecruiteesInRecruitmentListItem extends Component<
    OwnProps & StateProps & DispatchProps & WithTranslation,
    OwnState
    > {
    state: OwnState = {
        isReviewsModalOpen: false,
        scoreClickedInList: 0,
        isScoreModalOpen: false,
        isAppFormModalOpen: false,
        isCvModalOpen: false,
        isActionCallModalOpen: false,
        isActionAddNoteModalOpen: false,
        isActionMeetingModalOpen: false,
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const { t } = this.props;
        const photoUrl = endpoints.getRecruiteePhotoFileEndpoint(this.props.id);
        const stage = this.props.recruitmentStages[this.props.recruitmentRecruiteeStageId];
        const dropdownItems = this.renderDropdownItems();

        let cvUrl: string | undefined = undefined;
        if (this.props.newestJobApplicationId && this.props.newestResumeFileIdentifier) {
            cvUrl = endpoints.getJobApplicationFileEndpoint(
                this.props.newestJobApplicationId,
                this.props.newestResumeFileIdentifier
            );
        }

        const recruiteeViewPath = RoutePath.recruiteesRecruiteeDetails(this.props.newestJobApplicationId);

        return (
            <>
                <tr>
                    <td className='pl-4 pr-3'>
                        <Link to={recruiteeViewPath} title={t('recruitees.seeProfile')}>
                            <div className={styles.avatarContainer} style={{ backgroundImage: `url(${photoUrl})` }}>
                                <img src={photoUrl} alt={t('general.avatar')} />
                            </div>
                        </Link>
                    </td>
                    <td className={classNames(styles.colTitle, 'pl-0')}>
                        <Link to={recruiteeViewPath} title={t('recruitees.seeProfile')}>
                            <div className={styles.colTitleName}>
                                {this.props.firstName} {this.props.lastName}
                            </div>
                            <div className='d-flex'>
                                <button
                                    onClick={this.openCvModal}
                                    className={classNames('btn btn-white', styles.icon)}
                                    data-tip={t('recruitees.viewResume')}
                                    data-for={TOOLTIP.BOTTOM}>
                                    <i className='fa fa-file-text' />
                                </button>
                                <button
                                    onClick={this.openAppFormModal}
                                    className={classNames('btn btn-white m-l-sm m-r-sm', styles.icon)}
                                    data-tip={t('recruitees.viewApplicationForm')}
                                    data-for={TOOLTIP.BOTTOM}>
                                    <i className='fa fa-address-card' />
                                </button>
                                <button
                                    onClick={this.openCvFormPdf}
                                    className={classNames('btn btn-white', styles.icon)}
                                    data-tip={t('recruitees.sendCandidateProfile')}
                                    data-for={TOOLTIP.BOTTOM}>
                                    <i className='fa fa-share-square-o' />
                                </button>
                            </div>
                        </Link>
                    </td>
                    <td className={styles.colApplicationDate}>
                        <Link to={recruiteeViewPath} title={t('recruitees.seeProfile')}>
                            <div className='text-small'>{t('recruiteesInRecruitment.applicationDate')}:</div>
                            <div className='font-bold'>{date(this.props.newestJobApplicationDate)}</div>
                        </Link>
                    </td>
                    <td className={styles.colStage}>
                        <div className='text-small'>{t('recruiteesInRecruitment.recruitmentStage')}:</div>
                        {/* FIXME: wrapping this component in a Link bubbles click events to the Link */}
                        {stage && <RecruiteeInRecruitmentStageEditor stage={stage} recruitee={{ ...this.props }} />}
                    </td>
                    <td className={styles.colScore}>
                        <div className='text-small'>{t('recruiteesInRecruitment.myScore')}:</div>
                        <RecruiteeViewScore
                            id={this.props.id}
                            avgScore={this.props.averageScore}
                            score={this.props.scoreSetByMe}
                            reviewsCount={this.props.reviewCount}
                            starsCount={RECRUITEE_MAX_SCORE}
                            onScoreClick={this.openGiveScoreModal}
                            onReviewsClick={this.openReviewsModal}
                            className={classNames(styles.score, 'd-flex flex-column align-items-start')}
                        />
                    </td>
                    <td className={styles.colActions}>
                        {dropdownItems.length > 0 && (
                            <Dropdown trigger={this.renderActionsButton()} align='se'>
                                {dropdownItems}
                            </Dropdown>
                        )}
                    </td>
                </tr>

                {ReactDOM.createPortal(
                    <>
                        {cvUrl && this.state.isCvModalOpen && (
                            <RecruiteeCvModal
                                closeModal={this.closeModals}
                                cvUrl={cvUrl}
                                isOpen={this.state.isCvModalOpen}
                            />
                        )}

                        {this.state.isAppFormModalOpen && (
                            <RecruiteeAppFormModal
                                closeModal={this.closeModals}
                                isOpen={this.state.isAppFormModalOpen}
                                recruiteeId={this.props.id}
                                jobApplicationId={this.props.newestJobApplicationId}
                            />
                        )}

                        {this.state.isScoreModalOpen && (
                            <RecruiteeGiveScoreModal
                                isOpen={this.state.isScoreModalOpen}
                                closeModal={this.closeModals}
                                initialScore={this.state.scoreClickedInList}
                                onSubmit={this.onScoreSubmit}
                            />
                        )}

                        {this.state.isReviewsModalOpen && (
                            <RecruiteeReviewsModal
                                isOpen={this.state.isReviewsModalOpen}
                                closeModal={this.closeModals}
                                scoreSetByMe={this.props.scoreSetByMe}
                                averageScore={this.props.averageScore}
                                reviewCount={this.props.reviewCount}
                                recruiteeId={this.props.id}
                                recruitmentId={this.props.recruitmentId}
                            />
                        )}

                        {this.state.isActionCallModalOpen && (
                            <RecruiteeActionLogPhoneCallModal
                                isOpen={this.state.isActionCallModalOpen}
                                closeModal={this.closeModals}
                                onSubmit={this.onLogPhoneCallSubmit}
                            />
                        )}

                        {this.state.isActionAddNoteModalOpen && (
                            <RecruiteeActionAddNoteModal
                                isOpen={this.state.isActionAddNoteModalOpen}
                                closeModal={this.closeModals}
                                onSubmit={this.onAddNoteSubmit}
                            />
                        )}

                        {this.state.isActionMeetingModalOpen && (
                            <RecruiteeActionMeetingModal
                                isOpen={this.state.isActionMeetingModalOpen}
                                closeModal={this.closeModals}
                                onSubmit={this.onScheduleMeetingSubmit}
                            />
                        )}
                    </>,
                    document.getElementsByTagName('body')[0]
                )}
            </>
        );
    }

    private renderDropdownItems = () => {
        const { t } = this.props;
        const results: React.ReactNode[] = [];

        results.push(
            <DropdownItem
                key={`dropdown-item-call-${this.props.id}`}
                onClick={this.openActionCallModal}
                icon={<i className='fa fa-fw fa-phone' />}
                label={t('recruiteeView.action.call')}
            />
        );

        results.push(
            <DropdownItem
                key={`dropdown-item-meeting-${this.props.id}`}
                onClick={this.openActionMeetingModal}
                icon={<i className='fa fa-fw fa-calendar' />}
                label={t('recruiteeView.action.meeting')}
            />
        );

        results.push(
            <DropdownItem
                key={`dropdown-item-note-${this.props.id}`}
                onClick={this.openActionAddNoteModal}
                icon={<i className='fa fa-fw fa-file-text-o' />}
                label={t('recruiteeView.action.note')}
            />
        );

        return results;
    };

    private renderActionsButton = () => {
        return (
            <button className={classNames('btn btn-white', styles.btnActions)}>
                <i className='fa fa-ellipsis-v' />
            </button>
        );
    };

    private openCvModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.setState({
            isCvModalOpen: true
        });
    };

    private openAppFormModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.setState({
            isAppFormModalOpen: true
        });
    };

    private openGiveScoreModal = (score: number) => {
        this.setState({
            scoreClickedInList: score,
            isScoreModalOpen: true
        });
    };

    private openReviewsModal = () => {
        this.setState({
            isReviewsModalOpen: true
        });
    };

    private openCvFormPdf = (e: React.MouseEvent<HTMLButtonElement>) => {
        const downloadLink = endpoints.getDownloadRecruiteeFormAndResumeMergedEndpoint(
            this.props.newestJobApplicationId
        );

        window.location.href = downloadLink;
        e.preventDefault();
    };

    private openActionCallModal = () => this.setState({ isActionCallModalOpen: true });

    private openActionAddNoteModal = () => this.setState({ isActionAddNoteModalOpen: true });

    private openActionMeetingModal = () => this.setState({ isActionMeetingModalOpen: true });

    private closeModals = () =>
        this.setState({
            isCvModalOpen: false,
            isAppFormModalOpen: false,
            isScoreModalOpen: false,
            isReviewsModalOpen: false,
            isActionCallModalOpen: false,
            isActionAddNoteModalOpen: false,
            isActionMeetingModalOpen: false
        });

    private onScoreSubmit = (score: number, comment: string) => {
        this.closeModals();
        this.setScoreForRecruitee(this.props.id, score, comment);
    };

    private onLogPhoneCallSubmit = async (formData: LogPhoneCallFormValues) => {
        const { t } = this.props;
        const command = this.convertLogPhoneCallFormDataToLogRecruitmentRecruiteePhoneCallCommand({
            ...formData
        });

        this.closeModals();

        const results = await this.props.logPhoneCall(command);
        if (results.httpStatus < 300) {
            toastr.success(
                t('recruiteeView.logPhoneCallSuccess.message'),
                t('recruiteeView.logPhoneCallSuccess.title')
            );
        } else {
            toastr.error(t('recruiteeView.logPhoneCallError.message'), t('recruiteeView.logPhoneCallError.title'));
        }
    };

    private onAddNoteSubmit = async (formData: AddNoteFormValues) => {
        const { t } = this.props;
        const command = this.convertAddNoteFormDataToAddRecruitmentRecruiteeNoteCommand(formData);

        this.closeModals();

        const results = await this.props.addNote(command);
        if (results.httpStatus < 300) {
            toastr.success(t('recruiteeView.addNoteSuccessMessage'), t('general.success'));
        } else {
            toastr.error(t('recruiteeView.addNoteErrorMessage'), t('general.error'));
        }
    };

    private onScheduleMeetingSubmit = async (formData: ScheduleMeetingFormValues) => {
        const { t } = this.props;
        const command = this.convertMeetingFormDataToScheduleMeetingCommand(formData);

        this.closeModals();

        const results = await this.props.scheduleMeeting(command);
        if (results.httpStatus < 300) {
            toastr.success(t('recruiteeView.scheduleMeeting.successMessage'), t('general.success'));
        } else {
            toastr.error(t('recruiteeView.scheduleMeeting.errorMessage'), t('general.error'));
        }
    };

    private setScoreForRecruitee = async (recruiteeId: string, score: number, comment: string) => {
        await this.props.setScoreForRecruitee(recruiteeId, score, comment);
        this.props.getScoreForRecruiteeInRecruitment(recruiteeId, this.props.recruitmentId);
    };

    private convertLogPhoneCallFormDataToLogRecruitmentRecruiteePhoneCallCommand = (
        values: LogPhoneCallFormValues
    ): LogRecruitmentRecruiteePhoneCallCommand => {
        const callDateTime = moment(datetime(values.callDate, values.callTime))
            .utc()
            .toISOString(true);

        const command: LogRecruitmentRecruiteePhoneCallCommand = {
            ...values,
            callTime: callDateTime,
            recruitmentId: this.props.recruitmentId,
            recruiteeId: this.props.id
        };

        return command;
    };

    private convertAddNoteFormDataToAddRecruitmentRecruiteeNoteCommand = (
        values: AddNoteFormValues
    ): AddRecruitmentRecruiteeNoteCommand => {
        const command: AddRecruitmentRecruiteeNoteCommand = {
            text: values.noteText,
            recruitmentId: this.props.recruitmentId,
            recruiteeId: this.props.id
        };

        return command;
    };

    private convertMeetingFormDataToScheduleMeetingCommand = (
        values: ScheduleMeetingFormValues
    ): ScheduleMeetingCommand => {
        const meetingTime = moment(values.meetingTime, ['H:m', 'h:m']);
        const meetingDateTime = momentTz.tz(values.meetingDate!, values.timeZoneId);
        meetingDateTime.hours(meetingTime.hours());
        meetingDateTime.minutes(meetingTime.minutes());

        const command: ScheduleMeetingCommand = {
            recruitmentId: this.props.recruitmentId,
            recruiteeId: this.props.id,
            meetingName: values.meetingName,
            invitedPeople: values.invitedPeople
                .replace(/\t/, '')
                .split(/[,;]/)
                .filter(item => item.length > 0),
            durationInMinutes: values.durationInMinutes,
            timeZoneId: values.timeZoneId,
            messageContent: draftToHtml(convertToRaw(values.messageContent.getCurrentContent())),
            meetingDateTime: meetingDateTime
                .utc()
                .toISOString(true)
        };
        console.log(command);

        return command;
    };
}

const mapStateToProps = (state: State): StateProps => ({
    recruitmentStages: state.recruitmentRecruiteeStages.allStages.items as Dictionary<
        RecruitmentRecruiteeStageListViewDto
    >
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps): DispatchProps => ({
    setScoreForRecruitee: (recruiteeId: string, score: number, comment: string) =>
        dispatch(RecruiteesThunks.setScoreForRecruiteeInRecruitment(recruiteeId, props.recruitmentId, score, comment)),
    getScoreForRecruiteeInRecruitment: (recruiteeId: string, recruitmentId: string) =>
        dispatch(RecruiteesThunks.getScoreForRecruiteeInRecruitment(recruiteeId, recruitmentId)),
    logPhoneCall: (command: LogRecruitmentRecruiteePhoneCallCommand) =>
        dispatch(RecruiteesThunks.logPhoneCallForRecruiteeInRecruitment(command)),
    addNote: (command: AddRecruitmentRecruiteeNoteCommand) =>
        dispatch(RecruiteesThunks.addNoteForRecruiteeInRecruitment(command)),
    scheduleMeeting: (command: ScheduleMeetingCommand) => dispatch(RecruiteesThunks.scheduleMeeting(command)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteesInRecruitmentListItem));
