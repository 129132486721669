import { Preloader, SidebarLayoutFixedContent, TabsWrapper, TabsWrapperPaneArray } from '@emplo/react-inspinia';
import classNames from 'classnames';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import toastr from 'toastr';

import { Api } from '../../../api/_commons/http';
import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { endpoints } from '../../../api/endpoints';
import {
    QuestionnaireAddressAnswer,
    QuestionnaireAnswer,
    QuestionQuestionnaireAnswerType,
} from '../../../api/questionnaires/questionnaires.dto';
import {
    AddRecruitmentRecruiteeNoteCommand,
    LogRecruitmentRecruiteePhoneCallCommand,
    RECRUITEE_MAX_SCORE,
    ScheduleMeetingCommand,
} from '../../../api/recruitees/recruitees.dto';
import { RecruiteeViewDtoNormalized } from '../../../api/recruitees/recruitees.schema';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import {
    GetRecruitmentRecruiteeStageListForSetQueryResult,
} from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import { RecruitmentRecruiteeStagesThunks } from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import { TOOLTIP } from '../../../tooltips';
import { date, datetime } from '../../../utils/format';
import { listMapper } from '../../../utils/listMapper';
import ErrorPage from '../../pages/ErrorPage';
import RecruiteeGiveScoreModal from '../common/RecruiteeGiveScoreModal';
import RecruiteeReviewsModal from '../common/RecruiteeReviewsModal';
import RecruiteeActionAddNoteModal, { FormValues as AddNoteFormValues } from './action/RecruiteeActionAddNoteModal';
import RecruiteeActionLogPhoneCallModal, { FormValues } from './action/RecruiteeActionLogPhoneCallModal';
import RecruiteeActionMeetingModal, { FormValues as ScheduleMeetingFormValues } from './action/RecruiteeActionMeetingModal';
import ActivityPane from './ActivityPane';
import ApplicationFormPane from './applicationForm/ApplicationFormPane';
import RecruiteeStageSelect from './RecruiteeStageSelect';
import styles from './RecruiteeView.module.scss';
import RecruiteeViewScore from './RecruiteeViewScore';
import RecruitmentsModal from './RecruitmentsModal';
import ResumePane from './ResumePane';

interface RecruiteeViewStateProps {
    isLoading: boolean;
    isWriting: boolean;
    error: ErrorState;
    recruitee?: RecruiteeViewDtoNormalized;
    availableStages: GetRecruitmentRecruiteeStageListForSetQueryResult[];
}

interface RecruiteeViewDispatchProps {
    fetchRecruitee: () => Promise<void>;
    setScoreForRecruiteeInRecruitment: (
        recruitmentId: string,
        recruiteeId: string,
        score: number,
        comment: string
    ) => Promise<ThunkActionResult>;
    logPhoneCall: (command: LogRecruitmentRecruiteePhoneCallCommand) => Promise<ThunkActionResult>;
    addNote: (command: AddRecruitmentRecruiteeNoteCommand) => Promise<ThunkActionResult>;
    scheduleMeeting: (command: ScheduleMeetingCommand) => Promise<ThunkActionResult>;
    getScoreForRecruiteeInRecruitment: (recruiteeId: string, recruitmentId: string) => Promise<void>;
    fetchActivities: (recruitee: RecruiteeViewDtoNormalized, page: number) => void;
    fetchAvailableStages: (recruiteeId: string, recruitmentId: string) => void;
    updateRecruitmentStage: (recruiteeId: string, recruitmentId: string, stageId: string) => Promise<ThunkActionResult>;
}

interface RecruiteeViewRouteProps {
    jobApplicationId: string;
}

interface RecruiteeViewState {
    isRecruitmentsModalOpen: boolean;
    isGiveScoreModalOpen: boolean;
    isReviewsModalOpen: boolean;
    isActionCallModalOpen: boolean;
    isActionAddNoteModalOpen: boolean;
    isActionMeetingModalOpen: boolean;
    giveScoreInitialScore?: number;
    cvFile?: string;
    cvFileId?: string;
}

enum PanesTarget {
    RESUME = 'resume-tab',
    APPLICATION_FORM = 'application-form-tab',
    ACTIVITY = 'activity',
}

class RecruiteeView extends Component<
    RecruiteeViewStateProps &
        RecruiteeViewDispatchProps &
        WithTranslation &
        RouteComponentProps<RecruiteeViewRouteProps>,
    RecruiteeViewState
> {
    state: RecruiteeViewState = {
        isRecruitmentsModalOpen: false,
        isGiveScoreModalOpen: false,
        isReviewsModalOpen: false,
        isActionCallModalOpen: false,
        isActionAddNoteModalOpen: false,
        isActionMeetingModalOpen: false,
    };

    async componentDidMount() {
        ReactTooltip.rebuild();

        await this.props.fetchRecruitee();
        this.downloadResumeFile();
        if (this.props.recruitee && this.props.recruitee.authorizedData) {
            this.props.fetchActivities(this.props.recruitee, 1);
            this.props.fetchAvailableStages(
                this.props.recruitee.basicData.id,
                this.props.recruitee.authorizedData.recruitmentId
            );
        }
    }

    async componentDidUpdate(prevProps: RouteComponentProps<RecruiteeViewRouteProps>) {
        if (prevProps.match.params.jobApplicationId !== this.props.match.params.jobApplicationId) {
            // changed route path - we now want to display data from different job application
            // fetch new data -> fetch cv file from that new job application
            this.closeModals();
            await this.props.fetchRecruitee();
            this.downloadResumeFile();
            if (this.props.recruitee) {
                this.props.fetchActivities(this.props.recruitee, 1);
            }
        }
    }

    render() {
        const { t, isLoading, isWriting, error, recruitee } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <>
                <SidebarLayoutFixedContent
                    className='animated fadeIn'
                    title={t('recruiteeView.profile')}
                    breadcrumbs={
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'>
                                <Link to={RoutePath.recruiteesList()}>{t('general.mainMenu.recruitees')}</Link>
                            </li>
                            <li className='breadcrumb-item active'>
                                <strong>{t('recruiteeView.profile')}</strong>
                            </li>
                        </ol>
                    }>
                    {(isLoading || isWriting) && <Preloader overlay />}

                    {this.renderDetailsView()}
                </SidebarLayoutFixedContent>

                {recruitee && (
                    <RecruitmentsModal
                        isOpen={this.state.isRecruitmentsModalOpen}
                        closeModal={this.closeModals}
                        currentJobApplicationId={this.props.match.params.jobApplicationId}
                        recruitee={recruitee}
                    />
                )}

                {recruitee && (
                    <RecruiteeGiveScoreModal
                        isOpen={this.state.isGiveScoreModalOpen}
                        closeModal={this.closeModals}
                        initialScore={this.state.giveScoreInitialScore}
                        onSubmit={this.onScoreSubmit}
                    />
                )}

                {recruitee && recruitee.authorizedData && (
                    <RecruiteeReviewsModal
                        isOpen={this.state.isReviewsModalOpen}
                        closeModal={this.closeModals}
                        scoreSetByMe={recruitee.authorizedData.scoreSetByMe}
                        averageScore={recruitee.authorizedData.averageScore}
                        reviewCount={recruitee.authorizedData.reviewCount}
                        recruiteeId={recruitee.basicData.id}
                        recruitmentId={recruitee.authorizedData.recruitmentId}
                    />
                )}

                {recruitee && (
                    <RecruiteeActionLogPhoneCallModal
                        isOpen={this.state.isActionCallModalOpen}
                        closeModal={this.closeModals}
                        onSubmit={this.onLogPhoneCallSubmit}
                    />
                )}

                {recruitee && (
                    <RecruiteeActionAddNoteModal
                        isOpen={this.state.isActionAddNoteModalOpen}
                        closeModal={this.closeModals}
                        onSubmit={this.onAddNoteSubmit}
                    />
                )}

                {recruitee && (
                    <RecruiteeActionMeetingModal
                        isOpen={this.state.isActionMeetingModalOpen}
                        closeModal={this.closeModals}
                        onSubmit={this.onScheduleMeetingSubmit}
                    />
                )}
            </>
        );
    }

    private renderDetailsView = () => {
        const { t, recruitee } = this.props;

        if (!recruitee) {
            return null;
        }

        const photoUrl = endpoints.getRecruiteePhotoFileEndpoint(recruitee.basicData.id);
        let address: React.ReactNode = '';
        let tabPanel = null;

        if (recruitee.authorizedData) {
            // render tab panel if authorizedData is available
            const panes: TabsWrapperPaneArray<PanesTarget> = [
                { label: t('recruiteeView.paneResume'), content: this.renderResumePane(), target: PanesTarget.RESUME },
                {
                    label: t('recruiteeView.paneApplicationForm'),
                    content: this.renderApplicationFormPane(),
                    target: PanesTarget.APPLICATION_FORM,
                },
                {
                    label: t('recruiteeView.paneActivity'),
                    content: this.renderActivityPane(),
                    target: PanesTarget.ACTIVITY,
                },
            ];

            tabPanel = <TabsWrapper defaultTarget={PanesTarget.RESUME} panes={panes} />;

            // try to find address in the questionnaire answers
            const addressAnswer = recruitee.authorizedData.filledQuestionnaireAnswers.additionalQuestionnaireAnswers.find(
                this.isAddressAnswer
            );
            if (addressAnswer) {
                address = (addressAnswer as QuestionnaireAddressAnswer).value || <strong>-</strong>;
            }
        }

        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='ibox'>
                        <div className='ibox-content d-flex align-items-center'>
                            <div className='mr-3'>
                                <div className={styles.photoContainer} style={{ backgroundImage: `url(${photoUrl})` }}>
                                    <img src={photoUrl} alt={t('general.avatar')} />
                                </div>
                            </div>
                            <div className='flex-fill'>
                                <h2 className={classNames(styles.name, 'font-bold')}>
                                    {recruitee.basicData.firstName} {recruitee.basicData.lastName}
                                </h2>
                                <div className='row'>
                                    <div className='col-12 col-md-4'>
                                        <p className={classNames('font-bold mb-0', styles.phone)}>
                                            {recruitee.basicData.phoneNumber}
                                        </p>
                                        {recruitee.basicData.email && (
                                            <p className={classNames('font-bold mb-0', styles.email)}>
                                                {recruitee.basicData.email}
                                            </p>
                                        )}
                                    </div>
                                    <div className='col-12 col-md-5'>
                                        {address && (
                                            <>
                                                <span className='text-small'>{t('recruitees.address')}:</span>
                                                <p className={classNames('font-bold mb-0', styles.address)}>
                                                    {address}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {recruitee.authorizedData && (
                        <div className='ibox-content'>
                            <div className='row'>
                                <div className='col-12 col-lg-6'>
                                    <h2 className='font-bold'>{recruitee.authorizedData.appliedForPositionName}</h2>
                                    {t('recruiteeView.applicationDate')}:{' '}
                                    <strong>{date(recruitee.authorizedData.jobApplicationDate)}</strong>
                                    <div>
                                        <button
                                            className={classNames(
                                                'btn btn-link p-0 text-navy mt-1 mb-2',
                                                styles.btnOtherApplications
                                            )}
                                            onClick={this.openRecruitmentsModal}>
                                            <i className='fa fa-exchange pr-2' />
                                            {t('recruiteeView.viewOtherApplication', {
                                                name: recruitee.basicData.firstName,
                                            })}
                                        </button>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-6 d-flex flex-column align-items-end'>
                                    <RecruiteeViewScore
                                        id={recruitee.basicData.id}
                                        avgScore={recruitee.authorizedData.averageScore}
                                        score={recruitee.authorizedData.scoreSetByMe}
                                        reviewsCount={recruitee.authorizedData.reviewCount}
                                        starsCount={RECRUITEE_MAX_SCORE}
                                        displayScoreNumber
                                        onScoreClick={this.openGiveScoreModal}
                                        onReviewsClick={this.openReviewsModal}
                                        className={classNames(styles.score, 'd-flex flex-column align-items-end')}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 d-flex align-items-center'>
                                    {recruitee.basicData.phoneNumber && (
                                        <button
                                            type='button'
                                            className={classNames(styles.linkButton, 'mr-4')}
                                            data-tip={t('recruiteeView.action.call')}
                                            data-for={TOOLTIP.TOP}
                                            onClick={this.openActionCallModal}>
                                            <div className='btn btn-default btn-circle mb-1 mt-3'>
                                                <i className='fa fa-phone' />
                                            </div>
                                            <span className='text-small'>{t('recruiteeView.call')}</span>
                                        </button>
                                    )}
                                    {recruitee.basicData.email && (
                                        <a
                                            href={`mailto:${recruitee.basicData.email}`}
                                            className={classNames(styles.linkButton, 'mr-4')}
                                            data-tip={t('recruiteeView.action.email')}
                                            data-for={TOOLTIP.TOP}>
                                            <div className='btn btn-default btn-circle mb-1 mt-3'>
                                                <i className='fa fa-envelope' />
                                            </div>
                                            <span className='text-small'>{t('recruiteeView.e-mail')}</span>
                                        </a>
                                    )}
                                    <button
                                        type='button'
                                        className={classNames(styles.linkButton, 'mr-4')}
                                        data-tip={t('recruiteeView.action.meeting')}
                                        data-for={TOOLTIP.TOP}
                                        onClick={this.openActionMeetingModal}>
                                        <div className='btn btn-default btn-circle mb-1 mt-3'>
                                            <i className='fa fa-calendar' />
                                        </div>
                                        <span className='text-small'>{t('recruiteeView.meeting')}</span>
                                    </button>
                                    <button
                                        type='button'
                                        className={classNames(styles.linkButton)}
                                        data-tip={t('recruiteeView.action.note')}
                                        data-for={TOOLTIP.TOP}
                                        onClick={this.openActionAddNoteModal}>
                                        <div className='btn btn-default btn-circle mb-1 mt-3'>
                                            <i className='fa fa-file-text-o' />
                                        </div>
                                        <span className='text-small'>{t('recruiteeView.note')}</span>
                                    </button>
                                </div>
                                <div className='col-6 d-flex justify-content-end align-items-center'>
                                    <RecruiteeStageSelect
                                        availableStages={this.props.availableStages}
                                        onStageChange={this.onStageChange}
                                        stageId={recruitee.authorizedData.recruitmentRecruiteeStageId}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {recruitee.authorizedData && <div className='col-12 mt-4'>{tabPanel}</div>}
            </div>
        );
    };

    private renderResumePane = () => {
        const { recruitee } = this.props;

        if (!recruitee || !recruitee.authorizedData || !recruitee.resumeFileIdentifier) {
            return null;
        }

        const downloadUrl = endpoints.getJobApplicationFileEndpoint(
            recruitee.authorizedData.jobApplicationId,
            recruitee.resumeFileIdentifier
        );

        return <ResumePane key={this.state.cvFile} cvUrl={this.state.cvFile} cvDownloadUrl={downloadUrl} />;
    };

    private renderApplicationFormPane = () => {
        const { recruitee, match } = this.props;

        if (!recruitee || !recruitee.authorizedData) {
            return null;
        }

        return (
            <ApplicationFormPane
                answers={recruitee.authorizedData.filledQuestionnaireAnswers}
                filledQuestionnaireStructure={recruitee.authorizedData.filledQuestionnaireStructure}
                renderStaticContent={true}
                jobApplicationId={match.params.jobApplicationId}
            />
        );
    };

    private renderActivityPane = () => {
        const { recruitee } = this.props;

        if (!recruitee || !recruitee.authorizedData) {
            return null;
        }

        return (
            <ActivityPane recruiteeId={recruitee.basicData.id} recruitmentId={recruitee.authorizedData.recruitmentId} />
        );
    };

    private openRecruitmentsModal = () => this.setState({ isRecruitmentsModalOpen: true });

    private openGiveScoreModal = (clickedScore?: number) =>
        this.setState({ isGiveScoreModalOpen: true, giveScoreInitialScore: clickedScore });

    private openReviewsModal = () => this.setState({ isReviewsModalOpen: true });

    private openActionCallModal = () => this.setState({ isActionCallModalOpen: true });

    private openActionAddNoteModal = () => this.setState({ isActionAddNoteModalOpen: true });

    private openActionMeetingModal = () => this.setState({ isActionMeetingModalOpen: true });

    private closeModals = () =>
        this.setState({
            isReviewsModalOpen: false,
            isActionCallModalOpen: false,
            isGiveScoreModalOpen: false,
            isRecruitmentsModalOpen: false,
            isActionAddNoteModalOpen: false,
            isActionMeetingModalOpen: false,
        });

    private downloadResumeFile = async () => {
        const { recruitee } = this.props;

        if (
            recruitee &&
            recruitee.authorizedData &&
            recruitee.authorizedData.jobApplicationId &&
            recruitee.resumeFileIdentifier
        ) {
            const downloadResult = await Api().downloadFile(
                endpoints.getJobApplicationFileEndpoint(
                    recruitee.authorizedData.jobApplicationId,
                    recruitee.resumeFileIdentifier
                )
            );

            if (downloadResult.status === 200) {
                this.setState({
                    cvFile: URL.createObjectURL(downloadResult.data),
                    cvFileId: recruitee.resumeFileIdentifier,
                });
            }
        }
    };

    private onScoreSubmit = async (score: number, comment: string) => {
        if (!this.props.recruitee || !this.props.recruitee.authorizedData) {
            return;
        }

        this.closeModals();
        await this.props.setScoreForRecruiteeInRecruitment(
            this.props.recruitee.authorizedData.recruitmentId,
            this.props.recruitee.basicData.id,
            score,
            comment
        );
        this.props.getScoreForRecruiteeInRecruitment(
            this.props.recruitee.basicData.id,
            this.props.recruitee.authorizedData.recruitmentId
        );
        // reload events history
        this.props.fetchActivities(this.props.recruitee, 1);
    };

    private onLogPhoneCallSubmit = async (formData: FormValues) => {
        const { t } = this.props;
        const command = this.convertLogPhoneCallFormDataToLogRecruitmentRecruiteePhoneCallCommand({
            ...formData,
        });

        this.closeModals();

        const results = await this.props.logPhoneCall(command);
        if (results.httpStatus < 300) {
            toastr.success(
                t('recruiteeView.logPhoneCallSuccess.message'),
                t('recruiteeView.logPhoneCallSuccess.title')
            );
            // reload events history
            if (this.props.recruitee) {
                this.props.fetchActivities(this.props.recruitee, 1);
            }
        } else {
            toastr.error(t('recruiteeView.logPhoneCallError.message'), t('recruiteeView.logPhoneCallError.title'));
        }
    };

    private onAddNoteSubmit = async (formData: AddNoteFormValues) => {
        const { t } = this.props;
        const command = this.convertAddNoteFormDataToAddRecruitmentRecruiteeNoteCommand(formData);

        this.closeModals();

        const results = await this.props.addNote(command);
        if (results.httpStatus < 300) {
            toastr.success(t('recruiteeView.addNoteSuccessMessage'), t('general.success'));
            // reload events history
            if (this.props.recruitee) {
                this.props.fetchActivities(this.props.recruitee, 1);
            }
        } else {
            toastr.error(t('recruiteeView.addNoteErrorMessage'), t('general.error'));
        }
    };

    private onStageChange = async (stage: GetRecruitmentRecruiteeStageListForSetQueryResult) => {
        const { t } = this.props;

        if (this.props.recruitee && this.props.recruitee.authorizedData) {
            const results = await this.props.updateRecruitmentStage(
                this.props.recruitee.basicData.id,
                this.props.recruitee.authorizedData.recruitmentId,
                stage.id
            );

            if (results.httpStatus < 300) {
                toastr.success(t('recruiteeView.stageUpdated'), t('general.success'));
                // reload available stages
                this.props.fetchAvailableStages(
                    this.props.recruitee.basicData.id,
                    this.props.recruitee.authorizedData.recruitmentId
                );
                // reload activities
                this.props.fetchActivities(this.props.recruitee, 1);
            } else {
                toastr.error(t('recruiteeView.stageUpdateError'), t('general.error'));
            }
        }
    };

    private onScheduleMeetingSubmit = async (formData: ScheduleMeetingFormValues) => {
        const { t } = this.props;
        const command = this.convertMeetingFormDataToScheduleMeetingCommand(formData);

        this.closeModals();

        const results = await this.props.scheduleMeeting(command);
        if (results.httpStatus < 300) {
            toastr.success(t('recruiteeView.scheduleMeeting.successMessage'), t('general.success'));
            // reload events history
            if (this.props.recruitee) {
                this.props.fetchActivities(this.props.recruitee, 1);
            }
        } else {
            toastr.error(t('recruiteeView.scheduleMeeting.errorMessage'), t('general.error'));
        }
    };

    private isAddressAnswer = (answer: QuestionnaireAnswer) =>
        answer.$type === QuestionQuestionnaireAnswerType.QuestionnaireAddressAnswer;

    private convertLogPhoneCallFormDataToLogRecruitmentRecruiteePhoneCallCommand = (
        values: FormValues
    ): LogRecruitmentRecruiteePhoneCallCommand => {
        const callDateTime = moment(datetime(values.callDate, values.callTime)).utc().toISOString(true);

        const command: LogRecruitmentRecruiteePhoneCallCommand = {
            ...values,
            callTime: callDateTime,
            recruitmentId: this.props.recruitee!.authorizedData!.recruitmentId,
            recruiteeId: this.props.recruitee!.basicData.id,
        };

        return command;
    };

    private convertAddNoteFormDataToAddRecruitmentRecruiteeNoteCommand = (
        values: AddNoteFormValues
    ): AddRecruitmentRecruiteeNoteCommand => {
        const command: AddRecruitmentRecruiteeNoteCommand = {
            text: values.noteText,
            recruitmentId: this.props.recruitee!.authorizedData!.recruitmentId,
            recruiteeId: this.props.recruitee!.basicData.id,
        };

        return command;
    };

    private convertMeetingFormDataToScheduleMeetingCommand = (
        values: ScheduleMeetingFormValues
    ): ScheduleMeetingCommand => {
        const meetingTime = moment(values.meetingTime, ['H:m', 'h:m']);
        const meetingDateTime = momentTz.tz(values.meetingDate!, values.timeZoneId);
        meetingDateTime.hours(meetingTime.hours());
        meetingDateTime.minutes(meetingTime.minutes());

        const command: ScheduleMeetingCommand = {
            recruitmentId: this.props.recruitee!.authorizedData!.recruitmentId,
            recruiteeId: this.props.recruitee!.basicData.id,
            meetingName: values.meetingName,
            invitedPeople: values.invitedPeople
                .replace(/\t/, '')
                .split(/[,;]/)
                .filter((item) => item.length > 0),
            durationInMinutes: values.durationInMinutes,
            timeZoneId: values.timeZoneId,
            messageContent: draftToHtml(convertToRaw(values.messageContent.getCurrentContent())),
            meetingDateTime: meetingDateTime
                .utc()
                .toISOString(true),
        };

        return command;
    };
}

const mapStateToProps = (state: State): RecruiteeViewStateProps => ({
    isLoading:
        state.recruitees.recruiteeInApplication.isLoading ||
        state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.isLoading,
    isWriting: state.recruitees.recruiteeInApplication.isWriting,
    error:
        state.recruitees.recruiteeInApplication.error ||
        state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.error,
    recruitee: state.recruitees.recruiteeInApplication.recruitee,
    availableStages: listMapper(
        state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.list,
        state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.items
    ),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch,
    props: RouteComponentProps<RecruiteeViewRouteProps>
): RecruiteeViewDispatchProps => ({
    fetchRecruitee: () =>
        dispatch(RecruiteesThunks.getRecruiteeWithJobApplication(props.match.params.jobApplicationId)),
    setScoreForRecruiteeInRecruitment: (recruitmentId: string, recruiteeId: string, score: number, comment: string) =>
        dispatch(RecruiteesThunks.setScoreForRecruiteeInRecruitment(recruiteeId, recruitmentId, score, comment)),
    logPhoneCall: (command: LogRecruitmentRecruiteePhoneCallCommand) =>
        dispatch(RecruiteesThunks.logPhoneCallForRecruiteeInRecruitment(command)),
    addNote: (command: AddRecruitmentRecruiteeNoteCommand) =>
        dispatch(RecruiteesThunks.addNoteForRecruiteeInRecruitment(command)),
    scheduleMeeting: (command: ScheduleMeetingCommand) => dispatch(RecruiteesThunks.scheduleMeeting(command)),
    getScoreForRecruiteeInRecruitment: (recruiteeId: string, recruitmentId: string) =>
        dispatch(RecruiteesThunks.getScoreForRecruiteeInRecruitment(recruiteeId, recruitmentId)),
    fetchActivities: (recruitee: RecruiteeViewDtoNormalized, page: number = 1) =>
        dispatch(
            RecruiteesThunks.getRecruitmentRecruiteeActivities({
                recruiteeId: recruitee.basicData.id,
                recruitmentId: recruitee.authorizedData ? recruitee.authorizedData.recruitmentId : '',
                PageNumber: page,
                PageSize: PAGE_SIZE_DEFAULT,
            })
        ),
    fetchAvailableStages: (recruiteeId: string, recruitmentId: string) =>
        dispatch(RecruitmentRecruiteeStagesThunks.getStagesForRecruiteeInRecruitment(recruiteeId, recruitmentId)),
    updateRecruitmentStage: (recruiteeId: string, recruitmentId: string, stageId: string) =>
        dispatch(
            RecruitmentRecruiteeStagesThunks.setStageForRecruiteeInRecruitment(stageId, recruiteeId, recruitmentId)
        ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteeView)));
